import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import PedestrianImg from '../../../assets/images/pedestrian-gates-img.jpg'

class PedestrianGates extends React.Component {
	render() {
		const siteTitle = 'Pedestrian Gates - J and J Gate Service And Design'
		const siteDescription =
			'Are you shopping for a pedestrian gate Installed in the Arlington, Dallas and Fort Worth, TX Area? Call us For Automatic Custom Gates From JJ Gates'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Pedestrian Gates</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								Do you want a unique pedestrian gate to accent your fence?
								Looking for a company who can custom-build a pedestrian gate for
								your garden? J & J Gates Service and Design is here to help with
								all your gate needs! Call us at{' '}
								<a href="8174662794">817-466-2794</a> for pedestrian gate
								installation in Dallas and Fort Worth, TX.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>Types of Pedestrian Gates</h4>
							<p>
								Pedestrian gates are similar to width as the door in your home.
								If you’re a homeowner, you might use one to control access to
								important areas on your property such as the back yard, outdoor
								patio, swimming pool, or garden. Similarly, businesses may use
								pedestrian gates to secure an outdoor area where a door would
								not work. They might secure a courtyard, alleyway, work area,
								and more.
							</p>
							<p>
								Pedestrian gates serve a mostly decorative purpose, as you can
								make them look wonderful. So wonderful, in fact, that your
								neighbors might get a little jealous! But of course, they offer
								some security too. With J & J Gates Service and Design, your
								customization options have no limit. Our experienced
								professionals can help you come up with as many ideas as you
								want. From wrought iron to wood, arched to flat top, there are
								plenty of options to help you find the perfect pedestrian gate
								for your style and budget.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={PedestrianImg}
								alt="Pedestrian Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Outstanding Gate Installation and Repair</h4>
							<p>
								Do you need pedestrian gate{' '}
								<Link to="/gates/services/gate-repair/">repair</Link> in Dallas
								and Fort Worth, TX? In addition to installation, J & J Gates
								Service and Design are happy to repair any pedestrian gate, even
								if we did not install it. If a hinge is damaged or the materials
								deteriorating, just give us a call at{' '}
								<a href="8174662794">817-466-2794</a>. Our experienced gate
								professionals will get your gate working again in no time.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PedestrianGates

export const PedestrianGatesPageQuery = graphql`
	query PedestrianGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
